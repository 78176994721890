import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/vincepicone/Programming/Work/carbon-website/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const Caption = makeShortcode("Caption");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const DoDontExample = makeShortcode("DoDontExample");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`To create is to generate a new resource. Creating is distinct from adding, but the two are often confused. To add a resource is to include a resource that already exists.`}</p>
    </PageDescription>
    <h4 {...{
      "id": "status"
    }}>{`Status:`}</h4>
    <p><a parentName="p" {...{
        "href": "/experimental/overview/"
      }}>{`Experimental`}</a></p>
    <h4 {...{
      "id": "maintainer"
    }}>{`Maintainer:`}</h4>
    <p><a parentName="p" {...{
        "href": "https://github.com/vikkipaterson"
      }}>{`Vikki Paterson`}</a></p>
    <h4 {...{
      "id": "options"
    }}>{`Options:`}</h4>
    <AnchorLinks mdxType="AnchorLinks">
  <AnchorLink mdxType="AnchorLink">Standard creation</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Multistep creation</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Anatomy</AnchorLink>
    </AnchorLinks>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1120px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "53.75%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsSAAALEgHS3X78AAABW0lEQVQoz62Q3UvCUBiH/a/9wKA5ggii6M4ru/ImsCDv8irv8iYKXboZlOF0itvZp7a1X+c9dkJMSaIDz97tbHvO+3szaZriP1eGLo1GA3vFIhRFQS6XE2Sz2a0UCgWoqopSqYSD/TyO1DxOjw/BXHcpHI3GuH/ooNPR0G63oWkaer3eD7rdLvr9Pu5aLZTLZZxXKji7eIRSfcXV7TPSZLEUhmGElzcTY2vC5SPYto35fI4gCBBFEX8fimciSRKYpola7RL1+jWqTYaTG6D5tOCmZCn0/ADmxAXzQtgOA2MOh2E6nWI2m4nqOA5cHon26SBaYv6xB3wwvEecOP4Seh76hg6dxzKo6rqIZxgGLMsSAvqGhLLSAQLmwmaeqMm30PcxGAwwHJoiMkWSUIfrMnm/vh9LIc3I51Kal5yVZPWnTWwUUiR5Ms1IstrNemc7Cbd18OcOf4u3i/AT/dAsfU2hVUYAAAAASUVORK5CYII=')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Example",
        "title": "Example",
        "src": "/static/63c1adc9fdc2479a99b8107b660fe678/026f9/1.png",
        "srcSet": ["/static/63c1adc9fdc2479a99b8107b660fe678/d6747/1.png 288w", "/static/63c1adc9fdc2479a99b8107b660fe678/09548/1.png 576w", "/static/63c1adc9fdc2479a99b8107b660fe678/026f9/1.png 1120w"],
        "sizes": "(max-width: 1120px) 100vw, 1120px",
        "loading": "lazy"
      }}></img>{`
    `}</span>
    <Caption mdxType="Caption">Example of a creation pattern.</Caption>
    <h2 {...{
      "id": "standard-creation"
    }}>{`Standard creation`}</h2>
    <p>{`Standard creation is used in simple creation tasks when no stepped guidance is needed and the input fields are directly related to each other.`}</p>
    <h3 {...{
      "id": "modal"
    }}>{`Modal`}</h3>
    <p>{`If there are only one or two options to be made during a creation, use a small, standard modal overlaying the content where the creation was initiated. Avoid scrolling inside a small modal.`}</p>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "61.203007518796994%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAAsSAAALEgHS3X78AAABgklEQVQoz41Ty2rCQBQVpItCv6Lr0rWkUK1rf6C0RsR24Q901RaE/kBX7vtRiou4EYMm5v0iz9O5o5GpjeLA4dyZzD333MlMrSgK0CA+xDlD3EtcK4NTm48VE/PKuFYGURTBcRwO13U5B0FwEmEYwvM8znuHpaBt25hOp5jNZphMJlAUhW8mUIFjsCwLvu//F6TF9XqNzWYDTdOg6zpM0+QJpWPisohYrNIhtWwYBhcRhSgpjmPEScLaDOFwZ94ejuMiqBKkJBIhUXJJjtI0RcKE6FuSxIjCgBVw4bMi/o5pHkVhdcvkrPwxdKaiW8N0YFjszBwPlr0Di02bCQeCYJ7nfDIejyHLMvr9PrrdLo9luYee/ITH3hCNTwuNL+DmPWXIOG4/Mly/ZRj+bDVyUXA0GkGSJLTbbTSbzR1aaN3fQXro4Gpg4nII1AcF6i/guHhl9+4Z6Hxzf0xQuIfU2mKxgKqqB1hiqa4w1zIoGjDXiz9QtAIr+8yXUvF2TjzBLf8ChRVySRt8jiAAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Example of a standard creation modal.",
            "title": "Example of a standard creation modal.",
            "src": "/static/8a2647ef713cbc23648e909310b4208e/fb070/2.png",
            "srcSet": ["/static/8a2647ef713cbc23648e909310b4208e/d6747/2.png 288w", "/static/8a2647ef713cbc23648e909310b4208e/09548/2.png 576w", "/static/8a2647ef713cbc23648e909310b4208e/fb070/2.png 1152w", "/static/8a2647ef713cbc23648e909310b4208e/dcfb2/2.png 1330w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
        <Caption mdxType="Caption">Example of a standard creation modal.</Caption>
      </Column>
    </Row>
    <h3 {...{
      "id": "side-panel"
    }}>{`Side panel`}</h3>
    <p>{`Consider using a side panel to take advantage of the added screen real estate and keep the user in the context of where they are working. You may choose to use either the slide in or slide over panels.`}</p>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1120px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "63.74999999999999%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAAsSAAALEgHS3X78AAABo0lEQVQ4y62TSWsCQRCF80OF4CG44a6gjh7iIaKCIuJCTrnn4M0F7/6BnBNFGUVwltaoE42+TJUxqDEQwYE3Pd3T/XV1veqb7XaLaz439Go0GggEAohEIvB4PPB6vWfl9/t5zqGi0ShisRhyuRyWy+UOWKvVYLFY4HA4YLPZfslut8NqtSKZTKLX66Hb7WI4HEKWZdZoNOL+er3eAdvtNlwuF+8Yj8chSdKREokEwuEwqtUqNpsNFosFDMPgiPaiPqWPgc1mk6MLBoN8LJ/Px+3+m8adTify+Tzm8zkmkwkURTmSruvHQMoRRUM5ORXliKDlcpmBBNA07UeqqkIIcQwkM2jhadL3iSfTSqXSdYA0fjHwP0c+BBJkJw2K2eq6MA37BrZaLU4+OUyLT0UbhUIhVCoVdpMMEGKKqdBMqZjqCt5nB6bU63V2mUqDIjkVwdxuNxfveDxGv9/HYNBHT1bwNpzhVRYYjGf4NEuKgZ1OB9lslo9ULBb/VKFQQCaTQSqVRvrhHsHqC+6egNtHA9KzgcWHCbzkLtPc1Wp1VnRL6P8XWHo2D2wuuaMAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Example of a side panel creation flow",
            "title": "Example of a side panel creation flow",
            "src": "/static/465ed85f61a9cd9b7246d5bd7210f38d/026f9/3.png",
            "srcSet": ["/static/465ed85f61a9cd9b7246d5bd7210f38d/d6747/3.png 288w", "/static/465ed85f61a9cd9b7246d5bd7210f38d/09548/3.png 576w", "/static/465ed85f61a9cd9b7246d5bd7210f38d/026f9/3.png 1120w"],
            "sizes": "(max-width: 1120px) 100vw, 1120px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
        <Caption mdxType="Caption">Example of a side panel creation flow</Caption>
      </Column>
    </Row>
    <h3 {...{
      "id": "full-screen"
    }}>{`Full screen`}</h3>
    <p>{`If your creation flow consists of multiple fields and/or supporting imagery, consider the full screen option to take full advantage of the screen’s available real estate.`}</p>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1120px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "63.74999999999999%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAAsSAAALEgHS3X78AAABOklEQVQ4y8WUy0rDQBSG+8Cu3CSWNJKlSDf1Ddz4Bm7sQrvQooKihCZEUooKSUPIZXJp07S/OZMWm5pSqIKBLyfDnPPlzDBJY7FY4C+vBt263Su0JAmKokAURY4gCLXQnFTktmS5qGniWBIgS0dot9tI07QUWraN59cBBgMNqqpC0zQYhlGLruvo9/vo9W5we/+C60cTd09vME0Ts9msFCZJgvePT9jjMRzHQRAEmE6nmEwmFbIs43OdzhmawiFOzh9wcAGcXuaFI8V8npfCKIpgWxZc1wVjjEOFYRjWQvlxHBeRIY4CxCzk+Xm+FJJgOBxiNBrxDmlMRStoTKJVrMKWhN/C8m0RXxZt7CZ7Callipsy2t914Tb+T7i+5G3UCnd1sIsfQs/z4Pv+3lSODT3Qof0N9JXQf+ELwNTKs0jRn3oAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Example of a full-page creation flow.",
            "title": "Example of a full-page creation flow.",
            "src": "/static/e5df5247c62aed8c76caa3935a88d75e/026f9/4.png",
            "srcSet": ["/static/e5df5247c62aed8c76caa3935a88d75e/d6747/4.png 288w", "/static/e5df5247c62aed8c76caa3935a88d75e/09548/4.png 576w", "/static/e5df5247c62aed8c76caa3935a88d75e/026f9/4.png 1120w"],
            "sizes": "(max-width: 1120px) 100vw, 1120px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
        <Caption mdxType="Caption">Example of a full page creation flow.</Caption>
      </Column>
    </Row>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1120px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "63.74999999999999%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAAsSAAALEgHS3X78AAABhElEQVQ4y62Uy07CQBSGeWE3bopaMCyNYYNv4MoncCMbWCgJJkYNWgK2gbYhXNrSlrZcCr9zpozUSoiJknyctnPm65mZk+Y2mw3+85ejv2r1DgVZRqlUQj6f50iStBcak1lusVhE4ewU57KEonyCcrmMKIoS4XA4wvOrgrd3Ba1WC4qioNPp7KXdbqPRaKBer+G++YLao4qHpy5UVcVqtUqEQRiiZ+gYMfF4PIbneVgul1gsFpz5fM6hZ67rolK5wql0jIvrJo5ugMvbGGEYYb2OE6E1nUIze3BsG1N2TViWxSEBQS8RcTabIQgCFn0EvstIxuJYCG0X3e4HTNNkyx9iMpmg3+9D13UuFRLC931+v8Pf4u2EAUscDAawtxXSJJocsq2gjSZoyRR/J2Tlk4zKTk8gqRAKxDjFND+E6QGKJP+TUCSlk0koTnifUKzkoFAM0qFomsYxDINvSbaqgxU6jvPVMqJt6Jl4kThl2op0nuBb29AFNW0a6vos2ZxsPn0XPgHjC8ihYzfhrAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Example of a full-page creation flow",
            "title": "Example of a full-page creation flow",
            "src": "/static/294389cfb31b0a5574f71ad693649462/026f9/5.png",
            "srcSet": ["/static/294389cfb31b0a5574f71ad693649462/d6747/5.png 288w", "/static/294389cfb31b0a5574f71ad693649462/09548/5.png 576w", "/static/294389cfb31b0a5574f71ad693649462/026f9/5.png 1120w"],
            "sizes": "(max-width: 1120px) 100vw, 1120px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
        <Caption mdxType="Caption">Example of a full-page creation flow</Caption>
      </Column>
    </Row>
    <h2 {...{
      "id": "multistep-creation"
    }}>{`Multistep creation`}</h2>
    <p>{`If the creation task is complex, consider whether breaking the process up into steps could help the user. Consider displaying related or dependant options together.`}</p>
    <p>{`Multistep creation follows the same principles as `}<a parentName="p" {...{
        "href": "#standard-creation"
      }}>{`Standard creation`}</a>{`, with the addition of multisteps.`}</p>
    <h3 {...{
      "id": "side-panel-1"
    }}>{`Side panel`}</h3>
    <p>{`Consider using a side panel to take advantage of the added screen real estate and keep the user in the context of where they are working. You may choose to use either the slide in or slide over panels.`}</p>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1120px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "63.74999999999999%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAAsSAAALEgHS3X78AAABt0lEQVQ4y62Uy07CQBSGfUw3mLDiJndoC9SodMclxBgkunTLioSw5QF8A0O8pICEhF4oUEKR3zljRQSVmNjkYyYT5uv8Z056sFqt8J/PAf00m03E43EIgoBwOIxIJLIDrcdiMUiiCFEUGCJHkiRkMhkUi0XMZrN3Yb1eh8fjQSAQgM/n28Hv98Pr9eI8r+C+84jOwxPU3gBqt4d+r4fBgM1VFfP5/F3YarUQCoX422RZRi6X+4IsnyCdTuGqdgOHVWj5ClChHJq4j+M4WCwWn5HpdMlkkkenaDR+zGk9GAyiUqnAZrFGoxF0TYOu69DYSNAaSdfCaDTKT0P12CabzXJptXqF6XTKN2vap4zE3wppI8XehqSJRIIJq2shSTb5k5DWfxL+esL9kasbkTUXnbMjpOLTDdPmbehFJKzVarw1DMOAaZoYmzrGxoiNGkx9Q9hoNPgtUqOmUqkdqOEpAd3ycDjkPdftqnjua3h8mTAsPPVN1jausN1uQ1EUlMtl3vHfUSqVUCgU+P/Ozs6RP83i+OIOR9c2Di8NJG51TGZuHy6XSx5lH7Ztw7KsNRMXyxrz2tJ34Q2fEjJYStMIlwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Example of side panels in a multi-step creation flow",
            "title": "Example of side panels in a multi-step creation flow",
            "src": "/static/3143abb82651ced7ba237a2d475deaf6/026f9/6.png",
            "srcSet": ["/static/3143abb82651ced7ba237a2d475deaf6/d6747/6.png 288w", "/static/3143abb82651ced7ba237a2d475deaf6/09548/6.png 576w", "/static/3143abb82651ced7ba237a2d475deaf6/026f9/6.png 1120w"],
            "sizes": "(max-width: 1120px) 100vw, 1120px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
      </Column>
    </Row>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1120px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "63.74999999999999%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAAsSAAALEgHS3X78AAABt0lEQVQ4y62Uy07CQBSGfUw3mLDiJndoC9SodMclxBgkunTLioSw5QF8A0O8pICEhF4oUEKR3zljRQSVmNjkYyYT5uv8Z056sFqt8J/PAf00m03E43EIgoBwOIxIJLIDrcdiMUiiCFEUGCJHkiRkMhkUi0XMZrN3Yb1eh8fjQSAQgM/n28Hv98Pr9eI8r+C+84jOwxPU3gBqt4d+r4fBgM1VFfP5/F3YarUQCoX422RZRi6X+4IsnyCdTuGqdgOHVWj5ClChHJq4j+M4WCwWn5HpdMlkkkenaDR+zGk9GAyiUqnAZrFGoxF0TYOu69DYSNAaSdfCaDTKT0P12CabzXJptXqF6XTKN2vap4zE3wppI8XehqSJRIIJq2shSTb5k5DWfxL+esL9kasbkTUXnbMjpOLTDdPmbehFJKzVarw1DMOAaZoYmzrGxoiNGkx9Q9hoNPgtUqOmUqkdqOEpAd3ycDjkPdftqnjua3h8mTAsPPVN1jausN1uQ1EUlMtl3vHfUSqVUCgU+P/Ozs6RP83i+OIOR9c2Di8NJG51TGZuHy6XSx5lH7Ztw7KsNRMXyxrz2tJ34Q2fEjJYStMIlwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Example of a side panel multi-step creation flow",
            "title": "Example of a side panel multi-step creation flow",
            "src": "/static/3143abb82651ced7ba237a2d475deaf6/026f9/7.png",
            "srcSet": ["/static/3143abb82651ced7ba237a2d475deaf6/d6747/7.png 288w", "/static/3143abb82651ced7ba237a2d475deaf6/09548/7.png 576w", "/static/3143abb82651ced7ba237a2d475deaf6/026f9/7.png 1120w"],
            "sizes": "(max-width: 1120px) 100vw, 1120px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
        <Caption mdxType="Caption">Example of a side panel multi-step creation flow</Caption>
      </Column>
    </Row>
    <h3 {...{
      "id": "full-screen-multistep"
    }}>{`Full-screen multistep`}</h3>
    <p>{`If your creation flow consists of multiple fields and/or supporting imagery, consider the full screen option to take full advantage of the screen’s available real estate.`}</p>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1120px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "63.74999999999999%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAAsSAAALEgHS3X78AAABbElEQVQ4y62Sy2vCQBCH/a+1YqEx9NBDoUgPHu2lPfTgTS85lkKOpWCMEelDSSRi3g+SNL/urGywFe1z4ctsNsu3M7OplWWJ/xw1eiiKguNWC5IkodFocOr1+l6azSZkWUa73cbpyRHO5AYuO+eIk2QjtJZLPDxq0LQxRqMRxmMdhmHsMJlMMJvNoKoqut0uer0rXNxqkK5fMLh/Asp8I4zjBM+vJhPbMC0LjuMgTVNEUcS+xRx6J4qiwGKxQL/fx2A4xM1diI4CqEbKTMVGGIQRLNuHF8RwXA+e5zI8rFYrrNdrHl3Xhe/7fJ0OosH7nwfAm4cs9pDnQhgEmE6pJL0qTdd1tjaFbdtcQHtIKCIdwPF8lkTAI2XPhWEYYj6fwzQtLFk/LVa2gMr/LBPzbWitElKPSJqwWxK9EmzLDvFBSCWJk6lHgu1s9mV2ULhv41f8SPhddoTU/OrmfgG1qBLSJMuyP5HnOf8v3wFY28gsy8YCCwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Start of multistep creation flow",
            "title": "Start of multistep creation flow",
            "src": "/static/abe9bef9deccb3fc25317ac2c26c774e/026f9/8.png",
            "srcSet": ["/static/abe9bef9deccb3fc25317ac2c26c774e/d6747/8.png 288w", "/static/abe9bef9deccb3fc25317ac2c26c774e/09548/8.png 576w", "/static/abe9bef9deccb3fc25317ac2c26c774e/026f9/8.png 1120w"],
            "sizes": "(max-width: 1120px) 100vw, 1120px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
        <Caption mdxType="Caption">Start of multistep creation flow</Caption>
      </Column>
    </Row>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1120px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "63.74999999999999%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAAsSAAALEgHS3X78AAABeUlEQVQ4y6WTS2vCQBSF/dUatNBY6aJQKIVCXdpVF+3KRaG66K4UsmuMoAaFFB/ENG9jSmJO506MWB992IGTO4873xxuZnJJkoBaFn/bKD8VEC/YeDmfo0+z2cRRqQRRFFEoFCAIAo+7lM/nUSwWUalUUC6XcSoKODsp4OryHMF8ngL16RRyu4duT0Wn04GqqhgMBuj3+1vSNA2SJKFaraJWu8HFfRfHtyM8vLwxm1EKDIKAJ47HY+i6DsdxEIbhlubMQRRFGA6HqNfreGw0cPfs4fopgaSGjBSnQNfzoY0MmLYPy7Jh26mmzLlpmjAMg49d1+WHzWYzXrvFghUv8hjHwUfgsMOWQJ8lGO8WLLaJAJZlcVE/gxPM8zwu6mfztuPCcjwe4zhzyBJaLZmphXZb4VGWX6EoCiaTCXdEOevQTdHaCkgbyJHv+7yeNM5iBlmPu8BfgLT5p9P3re11uOngL/oWeKh21nD15w7UCkj3iS7sf0QwetufwUXIlcv0L/QAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Multistep creation flow with view all options toggle",
            "title": "Multistep creation flow with view all options toggle",
            "src": "/static/c7c63114ae689befd7ef92d5c8b5a001/026f9/9.png",
            "srcSet": ["/static/c7c63114ae689befd7ef92d5c8b5a001/d6747/9.png 288w", "/static/c7c63114ae689befd7ef92d5c8b5a001/09548/9.png 576w", "/static/c7c63114ae689befd7ef92d5c8b5a001/026f9/9.png 1120w"],
            "sizes": "(max-width: 1120px) 100vw, 1120px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
        <Caption mdxType="Caption">Multistep creation flow with view all options toggle</Caption>
      </Column>
    </Row>
    <h2 {...{
      "id": "anatomy"
    }}>{`Anatomy`}</h2>
    <h3 {...{
      "id": "button-position"
    }}>{`Button position`}</h3>
    <p>{`On a larger screen, buttons should always remain visible to the user.`}</p>
    <p>{`Buttons should be located in the top right and should stick upon scrolling.`}</p>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <img {...{
          "src": "/53c706d621cf2af9ba788504201d64d1/10.gif",
          "alt": "Button position in context"
        }}></img>
        <Caption mdxType="Caption">Buttons should be located top right and stick upon scrolling.</Caption>
      </Column>
    </Row>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1120px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "63.74999999999999%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAAsSAAALEgHS3X78AAABZklEQVQ4y62SzU/CMBiH+atBkQQd8eDBRI0XjktMvMgNPRhOHowEDQw2NjYIhFYcJB5kYUvYx892w8EIEKIuefa26dunfdumgiDAf34p/qtUKsjljpDP55FOp5HJZHaSzWZRKBQgCAJOjw9xJhzg+uoctuNEQtMcQ5I19Ho9GIaxk36/j2q1imKxCFEUcXmn4uSW4OF5AARuJLRtG+Z4Ans2C9vbmLHx+XwOMiQolUool+9x8/SFi0cfL7rDTF4ktKZTGJ0OOqqaQOO020sW/a6uY0Qp6HAIc6BhMpDxOabw/SASTplQVZSNcEG4wCLyfluWobRaEbKCpiSDEAp+wbEwsZMFXNhsNGKkej2UrOfxBd7pBuHPjlbjW62GVwaPNXYZLUlK5OwlXG3rrExd02LW83YKt5W9fqZ7lcyT+UA4kcd9Yfn8XCkhS6FlWTDYU+j+Ep09uY/RaCn0fR+u6/4Jz/NC4Td/zKAkCYL/XAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Button position in context",
            "title": "Button position in context",
            "src": "/static/cc32b095136a3e8648166953c2eb56f9/026f9/11.png",
            "srcSet": ["/static/cc32b095136a3e8648166953c2eb56f9/d6747/11.png 288w", "/static/cc32b095136a3e8648166953c2eb56f9/09548/11.png 576w", "/static/cc32b095136a3e8648166953c2eb56f9/026f9/11.png 1120w"],
            "sizes": "(max-width: 1120px) 100vw, 1120px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
        <Caption mdxType="Caption">Buttons should be located in the top right</Caption>
      </Column>
    </Row>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1120px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "63.74999999999999%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAAsSAAALEgHS3X78AAABL0lEQVQ4y62SPU+DQACG+xe7qMRBEwajo4kJkDqgq3Huj2FuaKdK+bDYmAJtIUgHIaUDH6/coS1WpbVK8vByl/eeI5dr5HmO/3wa5CVJEgShBVEUwXEceJ6vRRAEsCwL5ugQx6fnYM5auLy+RxwvS+FLMMfo2YHjOLAsq5bxeIyZ60K8uQVz0MTJVRvNOx8XbRuLZVIK4zjG1LExsQsK6VaKnu95mAcBAt9D4FpYvAYgx0eFYRhCV1VogwFNnWQdRUdVlJJirDwosG3ns/BR17di/DBPNphOJl+FhqatF79/DwmGsWKzt5Owmr1uFz1ZpsidDpR+n4qr0l8J6ZlW2OzUCvflW+HH35A0SO5K0Se3g1ynlTCKIjyZJkZ7Yg6HcGeztTDLMiRJ8ifSNKXCNxRZnEsBiYnxAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Button position in context when scrolling",
            "title": "Button position in context when scrolling",
            "src": "/static/6e0361ffe0e5bac922570e93a4b9728b/026f9/12.png",
            "srcSet": ["/static/6e0361ffe0e5bac922570e93a4b9728b/d6747/12.png 288w", "/static/6e0361ffe0e5bac922570e93a4b9728b/09548/12.png 576w", "/static/6e0361ffe0e5bac922570e93a4b9728b/026f9/12.png 1120w"],
            "sizes": "(max-width: 1120px) 100vw, 1120px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
        <Caption mdxType="Caption">Buttons should stick upon scrolling.</Caption>
      </Column>
    </Row>
    <p>{`When the full-screen dialog is viewed in mobile, the buttons are positioned at the bottom of the screen.`}</p>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "586px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "121.8430034129693%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAYCAYAAAD6S912AAAACXBIWXMAAAsSAAALEgHS3X78AAABr0lEQVQ4y+WV2U7CQBSGeQOvvfHpvPFSCCQsYUngCXwfwIDesIRAN7YypQQxYkKNLCnL75zBChcUASVeOMmf+Ttz5uuZnsnUs1qtQI36+Xx+krYZHsf8tDkMz3K5FMY0TUQiESQSCSSTSaFYLIZwOCzGSeSj0agYJ8XjcQSDQTDGBINYX0Bd1+H1esWCVColgATw+/0IBALwk7gnQCgUEqJ5n8+HRqOxAVKqzvc7tdm2vdmyY6bTKbqGAblWgyJJQjKJP+8SzUvVKgy+3clksimKA5zNZujx70iBmqJAleW9ohiKNbtdkcy3wEP0t8CzbLmuqjv9SRlKvJLVSkVUkrTtqcpHASmbcrGI+0wG+VwOj/k8stw/cJ9Np1EqFETMURnSgl/d8r5inFSUf3Sw1QOA6qfWQNMdSG+jc+bcNm7SZAkNhfdSFf2egdkuIL3leTAA4xet0emAuaijt6HV25A0BknV0WJPeB+7XF90Ubr9iGhuwUP7TMH1XRuXt8CVz8bFzRx5bZ3hYrkFpAWWZWE0Goneon5Lzvjr8AV1NkS5ZaHcHKHUtPA2ts/31/sAmrPVslL5wNEAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Button position in context on mobile",
            "title": "Button position in context on mobile",
            "src": "/static/79578f807c3da8fa0dd51c91a0147108/3eb4d/13.png",
            "srcSet": ["/static/79578f807c3da8fa0dd51c91a0147108/d6747/13.png 288w", "/static/79578f807c3da8fa0dd51c91a0147108/09548/13.png 576w", "/static/79578f807c3da8fa0dd51c91a0147108/3eb4d/13.png 586w"],
            "sizes": "(max-width: 586px) 100vw, 586px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
        <Caption mdxType="Caption">Example of full-page option on mobile</Caption>
      </Column>
    </Row>
    <Row mdxType="Row">
      <Column colMd={4} colLg={6} mdxType="Column">
        <DoDontExample type="do" caption="Buttons should stick upon scrolling so they are always visible." mdxType="DoDontExample">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1152px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsSAAALEgHS3X78AAABaklEQVQ4y+WUy07CQBhGeUwXGHkjl4SNQsJFUBDqmlBABUylBEs7dgUrWhYUaLuAQj87hUqxaIgYY+IkJzOdf3ryzfQSwg+30N8X2rbtDmi/XC63rFZYfQKt+df6HSFvcGzzHO8JtbGGWq2MMsuiXGFRv39As/WERrOFRmsNveae23h05qpV1lnPouKsH2vaNqEnVBUFuVwK6WwW6cwVrvMFlJg7FEvMGoZB4baIZCqNi3gCscsMYokbxJNZqKoaFJqmieFQcXvDMKDrOmazmYvuMBqp4DgOkcgZwuFTRM5lnERt5OsKYJmwPwrpjf1+H5PJBJqzBT90jtYEQUCn0wHP8+C7ItpdghdBdEIYwYQ00WAwwHQ6DQjpHK2JoghZlte8EgcJhEjurr4tlCRpA9lwpJAQssPBQnpu3nl+JaTsFR76UKjUT6/Xc9+KgHCxWLgp5/P5XmjNn9yPZVm/8OnR/hh2Ev6vH+wbEE34aTQ0QH8AAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Buttons should stick upon scrolling so they are always visible.",
              "title": "Buttons should stick upon scrolling so they are always visible.",
              "src": "/static/5a227d835b1628f5240bf36a929ea86f/fb070/14a.png",
              "srcSet": ["/static/5a227d835b1628f5240bf36a929ea86f/d6747/14a.png 288w", "/static/5a227d835b1628f5240bf36a929ea86f/09548/14a.png 576w", "/static/5a227d835b1628f5240bf36a929ea86f/fb070/14a.png 1152w", "/static/5a227d835b1628f5240bf36a929ea86f/c3bf2/14a.png 1200w"],
              "sizes": "(max-width: 1152px) 100vw, 1152px",
              "loading": "lazy"
            }}></img>{`
    `}</span>
        </DoDontExample>
      </Column>
      <Column colMd={4} colLg={6} mdxType="Column">
        <DoDontExample type="do" caption="If an illustration or image is displayed, buttons should be displayed above the image." mdxType="DoDontExample">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1152px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsSAAALEgHS3X78AAABkklEQVQ4y+WUy07CQBSGeUoXksATuXXBRkDlJgbwDaB4gxI20LSoAZoYwAC9J1za/s4pKRSILMQYEyf5M2dOZ77zz0zbEH64hf4+0HVdL6DecWzYjgPbdlh8WDTHV5AR8oNjm89YO1QVBdX7MspcFeUKh4fHJ9T5Bmp1fiOeR6PZxHOtDq7KsfkcKhwHRVU3Dn3gaDBENpvEVTqNq+sUcjd5FEt3KBRLa90WCkhlMognkohdpBBL5BC/TGM4HO4DLcvCgEEt04RpGDB0HbqmrcTij9EIPHMYjURwGg4jeibi5NxGvjIAbAvuLpAW9Xo9qMy+wrYfFOXoWbvdhiiKkCQJ0qsM8e0dgvgC0zT2HRrMVb/fh8Yc7QIpR8BOp+PNI+maynaiQZb73vhbQHJG48lkgvF47DmnNUcBCTSdTj0RmPJfAmVZXgOpun+elKNigiCszi8gOlO6UBy6FKruu6OYct1uF61Wy7uYXZnszdgDLhYLz+V8PsdsNtsS5ahgsFBQy+XyFz496o/RlsP/9YP9BJUw/J3NkR7CAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "If an illustration or image is displayed on the right, buttons should be displayed above the image.",
              "title": "If an illustration or image is displayed on the right, buttons should be displayed above the image.",
              "src": "/static/636fc42f6e0a577572388a8e0e325163/fb070/14b.png",
              "srcSet": ["/static/636fc42f6e0a577572388a8e0e325163/d6747/14b.png 288w", "/static/636fc42f6e0a577572388a8e0e325163/09548/14b.png 576w", "/static/636fc42f6e0a577572388a8e0e325163/fb070/14b.png 1152w", "/static/636fc42f6e0a577572388a8e0e325163/c3bf2/14b.png 1200w"],
              "sizes": "(max-width: 1152px) 100vw, 1152px",
              "loading": "lazy"
            }}></img>{`
    `}</span>
        </DoDontExample>
      </Column>
    </Row>
    <h3 {...{
      "id": "including-an-image-or-illustration"
    }}>{`Including an image or illustration`}</h3>
    <p>{`If including a supporting image or illustration, place the image on the right-hand side.`}</p>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1120px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "63.74999999999999%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAAsSAAALEgHS3X78AAABl0lEQVQ4y62Sy0/CQBCH+atBkQRT4sGDJhovHOHiQU6YGA8cPBlAL7zb8ijPUhSoUGgxLfzcWSwCIZCoTb5Os935Znd2PYvFAv/5eOiVSCQQCJwgGAzC6/XC5/Ptxe/3IxQKQRAEnJ0e41w4ws31BUzLWgo1rYdMXkS1WkW5XN6LoihIpdMIh8OIRKK4iokQblt4eK4DC3spNE0TWu8NFqswY/A4m/FI/1ymkwk+2Xiz2UQsdod4/B7RJx2XjzaSssVMzlJojMcoSyJymQxy2ewS9l0qFCCVShyxWESdra7RaKDVakEfDnmeNeph9tGG/q7Cmc9/hMV8Hi+pFF7Zdoh0MsmlJCJxMZdDp80SdR1DJlNVlcfJ1MJAH6Hb1TB3hWMmpERZFDdwZRSpoNrp8LmDfh/9NaiIpu0QUvI2NL4uHLG5roRWeFDoCrahf7IkoVapoMZuA48MhW6GLPNidKc3hIfgvWQrXYfGCt/93RC6k/lWKe5iR0tcYZud/EpoGAZfduWXUCu67NRXQmqmbdt/wnEcLvwCaNanP5pft9kAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Example of supporting image placement",
            "title": "Example of supporting image placement",
            "src": "/static/383e86f7f36cfa421ee71e8385f882b4/026f9/15.png",
            "srcSet": ["/static/383e86f7f36cfa421ee71e8385f882b4/d6747/15.png 288w", "/static/383e86f7f36cfa421ee71e8385f882b4/09548/15.png 576w", "/static/383e86f7f36cfa421ee71e8385f882b4/026f9/15.png 1120w"],
            "sizes": "(max-width: 1120px) 100vw, 1120px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
        <Caption mdxType="Caption">Example of supporting image placement</Caption>
      </Column>
    </Row>
    <h3 {...{
      "id": "show-all-available-options-toggle"
    }}>{`Show all available options toggle`}</h3>
    <p>{`The toggle should be used to avoid overwhelming users, while still allowing users to access all options. It can be applied to the `}<a parentName="p" {...{
        "href": "#side-panel"
      }}>{`side panel`}</a>{` and the `}<a parentName="p" {...{
        "href": "#full-screen"
      }}>{`full screen`}</a>{` dialog.`}</p>
    <p>{`Consider having a minimal set of options visible by default, offering the ability to chose if the user wants to expose more options.`}</p>
    <p>{`If the user switches to this mode, remember this for when they next perform the same task.`}</p>
    <Row mdxType="Row">
      <Column colMd={4} colLg={6} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "792px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "72.72727272727273%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAAsSAAALEgHS3X78AAAArklEQVQ4y8VTUQrDIAzt/Q/kLfz0q/hfKGotqMVW3zAw6Ibt6lZYIERC8vISkw43S3c7YM4ZRYs83zVfM8NaUgtQleE0TaTee2zb9h3DfYKUEpxzMMYghDgdw2nL+8B1XWGthXOumvCJ7QvDGCNpSolsAV2WBSEEspcYvs9wGAYopTCOI4wx5Ct2nmcq1ASotUbf9wRW2v655aMdbPqUWtDRYl9Zn0uX8t9bvhvwAS90pH9/U5BiAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Show all options off",
            "title": "Show all options off",
            "src": "/static/39d91c71c40da516a9bf906c71f84bd7/b87cb/16.png",
            "srcSet": ["/static/39d91c71c40da516a9bf906c71f84bd7/d6747/16.png 288w", "/static/39d91c71c40da516a9bf906c71f84bd7/09548/16.png 576w", "/static/39d91c71c40da516a9bf906c71f84bd7/b87cb/16.png 792w"],
            "sizes": "(max-width: 792px) 100vw, 792px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
        <Caption mdxType="Caption">Show all options off</Caption>
      </Column>
      <Column colMd={4} colLg={6} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "792px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "72.72727272727273%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAAsSAAALEgHS3X78AAAAyUlEQVQ4y8VTzQrCMAze+598Aw8+gLe9xU4yBKEHD3qy61rYD+3STxcZDC2zHQPThv4l4fuSJsPGkm0e0HuPUUeZ9qG7ZIQhp5RAQYR1XbO2bYthGNYhnDsIIVAUBfI8R1mWi2lYpDwZEBGcc9Bao2maoMMvtIwQPF/DEcgS07XWMvW+79F1Ha9RCOdUro8bLncBpRSklKiqinM6no0xzCAqIPm34f58xO50gFaa0a2izHQnI/9dhOSiLBl9vsV8n6hO+W8vbx3wCfBUo05mXkRkAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Show all options on",
            "title": "Show all options on",
            "src": "/static/aa2b05f8e19b6f766e0823f92a69b977/b87cb/17.png",
            "srcSet": ["/static/aa2b05f8e19b6f766e0823f92a69b977/d6747/17.png 288w", "/static/aa2b05f8e19b6f766e0823f92a69b977/09548/17.png 576w", "/static/aa2b05f8e19b6f766e0823f92a69b977/b87cb/17.png 792w"],
            "sizes": "(max-width: 792px) 100vw, 792px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
        <Caption mdxType="Caption">Show all options on.</Caption>
      </Column>
    </Row>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1120px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "63.74999999999999%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAAsSAAALEgHS3X78AAABOklEQVQ4y8WUy0rDQBSG+8Cu3CSWNJKlSDf1Ddz4Bm7sQrvQooKihCZEUooKSUPIZXJp07S/OZMWm5pSqIKBLyfDnPPlzDBJY7FY4C+vBt263Su0JAmKokAURY4gCLXQnFTktmS5qGniWBIgS0dot9tI07QUWraN59cBBgMNqqpC0zQYhlGLruvo9/vo9W5we/+C60cTd09vME0Ts9msFCZJgvePT9jjMRzHQRAEmE6nmEwmFbIs43OdzhmawiFOzh9wcAGcXuaFI8V8npfCKIpgWxZc1wVjjEOFYRjWQvlxHBeRIY4CxCzk+Xm+FJJgOBxiNBrxDmlMRStoTKJVrMKWhN/C8m0RXxZt7CZ7Callipsy2t914Tb+T7i+5G3UCnd1sIsfQs/z4Pv+3lSODT3Qof0N9JXQf+ELwNTKs0jRn3oAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Show all options off",
            "title": "Show all options off",
            "src": "/static/e5df5247c62aed8c76caa3935a88d75e/026f9/18.png",
            "srcSet": ["/static/e5df5247c62aed8c76caa3935a88d75e/d6747/18.png 288w", "/static/e5df5247c62aed8c76caa3935a88d75e/09548/18.png 576w", "/static/e5df5247c62aed8c76caa3935a88d75e/026f9/18.png 1120w"],
            "sizes": "(max-width: 1120px) 100vw, 1120px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
        <Caption mdxType="Caption">Show all options off</Caption>
      </Column>
    </Row>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1120px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "63.74999999999999%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAAsSAAALEgHS3X78AAABXUlEQVQ4y62UX0+CUBiH/cLddIMZ0rxszRv7Bl31CbrJG70oN9uaNQtHA5bNJZMFHA+IgL94D7JSdLEm28O7czjv855/o7JarXDIp0KvdvsWdVlGo9FAtVoVSJK0E/omp2MVRUH9tIYzWYIin6DZbCIIgkw4nVoYPKt4eVUxHA6hqio0TdvJaDRCr9dDt9vBXf8JnQcd949v0HUdURRlQu5zmB9jIbYsC67rIgxDLBaLDZbLpfjWal2iJh3j/KqPo2vg4iaG7wdIkjgTuszF++cYzpcDxpiAEj3P28l8PgfnPI0MPM3lzBPj43gtJIFpmJhMJrBtW7QpiSBBXoQoFmBrvB8hLZml1WhTc2iJFP8nTKdPHb7vb0ipvS3cR0FIe0DxIMI8kWJ+or+Ff1EQUgfdI9M0YRiGOBzHcUrNbO+SZ7OZkOQV81OmvjJsXJskScQt34YuclloPP0XvgHPK8nbdbwjCQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Show all options on.",
            "title": "Show all options on.",
            "src": "/static/5bb86b1e9940a6ed15f85285b580bd67/026f9/19.png",
            "srcSet": ["/static/5bb86b1e9940a6ed15f85285b580bd67/d6747/19.png 288w", "/static/5bb86b1e9940a6ed15f85285b580bd67/09548/19.png 576w", "/static/5bb86b1e9940a6ed15f85285b580bd67/026f9/19.png 1120w"],
            "sizes": "(max-width: 1120px) 100vw, 1120px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
        <Caption mdxType="Caption">Show all options on. </Caption>
      </Column>
    </Row>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1120px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "63.74999999999999%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAAsSAAALEgHS3X78AAABhElEQVQ4y62Uy07CQBSGeWE3bopaMCyNYYNv4MoncCMbWCgJJkYNWgK2gbYhXNrSlrZcCr9zpozUSoiJknyctnPm65mZk+Y2mw3+85ejv2r1DgVZRqlUQj6f50iStBcak1lusVhE4ewU57KEonyCcrmMKIoS4XA4wvOrgrd3Ba1WC4qioNPp7KXdbqPRaKBer+G++YLao4qHpy5UVcVqtUqEQRiiZ+gYMfF4PIbneVgul1gsFpz5fM6hZ67rolK5wql0jIvrJo5ugMvbGGEYYb2OE6E1nUIze3BsG1N2TViWxSEBQS8RcTabIQgCFn0EvstIxuJYCG0X3e4HTNNkyx9iMpmg3+9D13UuFRLC931+v8Pf4u2EAUscDAawtxXSJJocsq2gjSZoyRR/J2Tlk4zKTk8gqRAKxDjFND+E6QGKJP+TUCSlk0koTnifUKzkoFAM0qFomsYxDINvSbaqgxU6jvPVMqJt6Jl4kThl2op0nuBb29AFNW0a6vos2ZxsPn0XPgHjC8ihYzfhrAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Show all options on and grouped.",
            "title": "Show all options on and grouped.",
            "src": "/static/294389cfb31b0a5574f71ad693649462/026f9/20.png",
            "srcSet": ["/static/294389cfb31b0a5574f71ad693649462/d6747/20.png 288w", "/static/294389cfb31b0a5574f71ad693649462/09548/20.png 576w", "/static/294389cfb31b0a5574f71ad693649462/026f9/20.png 1120w"],
            "sizes": "(max-width: 1120px) 100vw, 1120px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
        <Caption mdxType="Caption">Show all options on and grouped. </Caption>
      </Column>
    </Row>
    <h3 {...{
      "id": "confirmation"
    }}>{`Confirmation`}</h3>
    <p>{`A notification banner can be presented to visually confirm when any create action is a success.`}</p>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1120px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "63.74999999999999%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAAsSAAALEgHS3X78AAABEUlEQVQ4y62R22qDQBCGfWJvBK0+gm9kKYUS6EUuvChJsZaENVjBqFU8ra2Hv51NMaS0tNEsfDuru/sxOyMNw4BLDokmy7IgyzIMw4Cqqv9C0zRoVxp0XYeiKDBNE5zzgzAMQ9i2Ddd14XkeGGO/smM7rLcOrp8WuH28x81qgbvNEg8vDrr39iCs6xpBEAhxkiQiRlGEOI7FmiJ9h/sQr3ECPw6wKrZYpxs4OcPzmw+/2WPo+qOwqqox/kX9Ca+5OE/PbJoGZVmi6zpI1BT6Obc5JG3b9ijs+15Iz6Envu6R40RI6dLmVEYhpUt1yfMcRVFMhu6LGn4XUpzKj8KLZZimKbIsm8UopKJSQedAMmrwB5+T1Fw2f/eIAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Example of a successful creation confirmation",
            "title": "Example of a successful creation confirmation",
            "src": "/static/8f6263c8078f2ebb7a9b41469da2321d/026f9/27.png",
            "srcSet": ["/static/8f6263c8078f2ebb7a9b41469da2321d/d6747/27.png 288w", "/static/8f6263c8078f2ebb7a9b41469da2321d/09548/27.png 576w", "/static/8f6263c8078f2ebb7a9b41469da2321d/026f9/27.png 1120w"],
            "sizes": "(max-width: 1120px) 100vw, 1120px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
        <Caption mdxType="Caption">Example of a successful creation confirmation</Caption>
      </Column>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      